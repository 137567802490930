import subscribeUserToPush, { requestPermission } from '../swRegistration';
import api from './api';
import { getInstance } from './util';
import { REACT_APP_RESET_PASSWORD_URL } from '../config';
import axios from 'axios'; // Import Axios
import { IdentificationId } from './util';
import {
  getIdentificationId,
  getPortalServerIp,
  getUserName,
  getStaffUserName,
} from '../storage';

const RegisterDevice = async ({ userName, password, IpAddress }) => {
  return api
    .post('/DiscoveryClient/ios/RegisterDevice', {
      request: getInstance({ IpAddress }),
      userName,
      password,
    })
    .then((res) => {
      subscribeUserToPush();
      return res.data;
    });
};

const ResetPassword = async ({ userName }) => {
  let data = {};
  data = IdentificationId();
  data.identificationId = '';
  data.userName = userName;

  return axios
    .post(REACT_APP_RESET_PASSWORD_URL + '/PortalStaff/ios/ResetPassword', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('Error resetting password:', error);
    });
};

const UpdatePassword = async ({ oldPassword, newPassword }) => {
  api.defaults.baseURL = getPortalServerIp();
  let data = {};
  data = IdentificationId();
  data.oldPassword = oldPassword;
  data.newPassword = newPassword;
  data.userName = getStaffUserName();
  return api.post('/PortalStaff/ios/SetPassword', data).then((res) => {
    console.log(res.data);
    return res.data;
  });
};

export { RegisterDevice, ResetPassword, UpdatePassword };
